<template>
  <span v-if="has(field)" :class="wrapperClasses">
    {{ first(field) }}
  </span>
</template>

<script>
import { has as _has } from 'lodash'

export default {
  name: 'FormError',
  inheritAttrs: false,
  props: {
    errors: {
      type: Object,
      default: () => {}
    },
    field: {
      type: String,
      required: true
    },
    wrapperClasses: {
      type: String,
      default: 'txt-required'
    }
  },

  methods: {
    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    first(field) {
      if (this.errors[field]) {
        return this.errors[field][0]
      }
    },

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field
     */
    has(field) {
      return _has(this.errors, field)
    }
  }
}
</script>
