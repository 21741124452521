export default {
  methods: {
    backToRedirectPath() {
      if (this.$route.query.redirect) {
        const resolvedRoute = this.$router.resolve(this.$route.query.redirect)
        if (resolvedRoute.resolved.matched.length) {
          return this.$router.push(this.$route.query.redirect)
        }
      }

      return this.$router.push(this.localePath('/'))
    }
  }
}
