<template>
  <div class="input-group">
    <div class="input-group-prepend" @click="switchVisibility">
      <svg
        v-if="passwordFieldType === 'text'"
        class="input-group-prepend__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
        />
      </svg>
      <svg
        v-else
        class="input-group-prepend__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
        />
      </svg>
    </div>
    <input
      :required="isRequired"
      :class="inputClass"
      class="form-input"
      placeholder="00000000"
      autocomplete="current-password"
      :type="passwordFieldType"
      @input="updateValue($event.target.value)"
    />
  </div>
</template>
<script>
export default {
  name: 'PasswordInput',
  props: {
    inputClass: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      password: '',
      passwordFieldType: 'password'
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    updateValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss">
.input-group-prepend {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);

  &__icon {
    fill: #959393;
    pointer-events: none;
    width: 1rem;
  }
}
</style>
