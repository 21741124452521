<template>
  <button
    class="btn-custom bg-blue facebook-login-btn"
    type="button"
    @click="buttonClicked"
  >
    <img class="ic-fb" v-lazy-load data-src="~assets/img/ic-facebook.png" />
    <span class="ml-1"><slot></slot></span>
  </button>
</template>
<script>
import { FB_SDK_VERSION, FB_LOGIN_STATUS_CONNECTED } from '@/constants/config'

export default {
  props: {
    loginOptions: {
      type: Object,
      default() {
        return {
          scope: 'public_profile, email'
        }
      }
    }
  },

  mounted() {
    this.loadFbSdk(this.$config.FB_CLIENT_ID, FB_SDK_VERSION).then(
      (response) => {
        this.$emit('get-initial-status', response)
        this.$emit('sdk-loaded', {
          isConnected: this.isConnected,
          FB: window.FB
        })
      }
    )
  },

  methods: {
    buttonClicked() {
      this.$emit('click')
      this.login()
    },
    login() {
      this.fbLogin(this.loginOptions).then((response) => {
        if (response.status === FB_LOGIN_STATUS_CONNECTED) {
          this.$emit('login-success', {
            response,
            FB: window.FB
          })
        }
      })
    },
    logout() {
      this.fbLogout().then((response) => {
        this.$emit('logout', response)
      })
    },

    loadFbSdk(appId, version) {
      const currentLocaleObject = this.$i18n.locales.find(
        (locale) => locale.code === this.$i18n.locale
      )

      const isoLocale = currentLocaleObject ? currentLocaleObject.iso : 'en_US'
      const FB_SDK_JS_URL = `https://connect.facebook.net/${isoLocale}/sdk.js#xfbml=1&version=v8.0`

      return new Promise((resolve) => {
        window.fbAsyncInit = function() {
          // eslint-disable-next-line no-undef
          FB.init({
            appId,
            version: 'v13.0',
            cookie: true
          })
          // eslint-disable-next-line no-undef
          FB.AppEvents.logPageView()
          resolve('SDK Loaded!')
        }
        ;(function(d, s, id) {
          // eslint-disable-line func-names
          const fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) {
            return
          }
          const js = d.createElement(s)
          js.id = id
          js.src = FB_SDK_JS_URL
          fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
      })
    },

    getFbLoginStatus() {
      return new Promise((resolve) => {
        window.FB.getLoginStatus((responseStatus) => {
          resolve(responseStatus)
        })
      })
    },

    fbLogin(options) {
      return new Promise((resolve) => {
        window.FB.login((response) => resolve(response), options)
      })
    },
    fbLogout() {
      return new Promise((resolve) => {
        window.FB.logout((response) => resolve(response))
      })
    }
  }
}
</script>
