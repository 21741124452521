<template>
  <div class="loginPage flex-1">
    <div class="yl-content">
      <section class="form">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="form__title text-center">
                <h4>{{ $t('login') }}</h4>
              </div>
              <div v-if="errorMessage" class="notice-box">
                <div class="notice-txt-error">
                  {{ $t('authentication_error') }}
                </div>
                <div class="notice-txt">
                  {{ errorMessage }}
                </div>
                <nuxt-link
                  v-if="showForgotPass"
                  class="text-link"
                  :to="localePath('forgot_password')"
                  >{{ $t('forgot_password') }}</nuxt-link
                >
              </div>
              <div class="form__content">
                <form @submit.prevent="onLogin">
                  <div class="form-group">
                    <label class="form-label">{{ $t('email_address') }}</label>
                    <input
                      v-model="login.email"
                      required
                      autocomplete="email"
                      class="form-input"
                      type="text"
                      :placeholder="`${$t('example')}　taro.yamada@example.com`"
                      :class="{ 'is-invalid': validationErrors.email }"
                    />
                    <FormError
                      :errors="validationErrors"
                      field="email"
                    ></FormError>
                  </div>
                  <div class="form-group mb-0">
                    <label class="form-label">{{ $t('login_password') }}</label>
                    <div class="input-group">
                      <PasswordInput
                        v-model="login.password"
                        :input-class="[
                          { 'is-invalid': validationErrors.password }
                        ]"
                      />
                    </div>
                    <FormError
                      class="d-flex"
                      :errors="validationErrors"
                      field="password"
                    ></FormError>
                    <nuxt-link
                      class="btn-forgot-pw"
                      :to="localePath('forgot_password')"
                      >{{ $t('i_forgot_my_password') }}</nuxt-link
                    >
                  </div>
                  <button
                    class="btn-custom"
                    type="submit"
                    :disabled="isLoading"
                  >
                    {{ $t('login') }}
                  </button>
                  <div class="text-other">
                    <span>{{ $t('or') }}</span>
                  </div>
                  <FacebookLoginButton
                    :disabled="isLoading"
                    @login-success="onLoginWithFacebook"
                    >{{ $t('login_with_facebook') }}</FacebookLoginButton
                  >
                  <div class="text-center">
                    <nuxt-link
                      class="text-link"
                      :to="
                        localePath({
                          path: '/register'
                        })
                      "
                      >{{
                        $t('register_if_you_do_not_have_an_account')
                      }}</nuxt-link
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PasswordInput from '@/components/common/PasswordInput'
import routeMixin from '@/minxins/routeMixin'
import FacebookLoginButton from '@/components/common/FacebookLoginButton'
import FormError from '@/components/common/FormError'
import HeadMeta from '@/minxins/headMeta'

export default {
  name: 'Login',
  layout: 'basic',
  middleware: 'guest',
  components: {
    PasswordInput,
    FacebookLoginButton,
    FormError
  },
  mixins: [routeMixin, HeadMeta],
  data() {
    return {
      hasError: false,
      isLoading: false,
      login: {
        email: '',
        password: ''
      },
      validationErrors: {},
      errorMessage: '',
      showForgotPass: false,
      headMeta: {
        title: this.$t('headMeta.login.title'),
        description: this.$t('headMeta.login.description')
      }
    }
  },

  methods: {
    async onLogin() {
      this.errorMessage = ''
      this.isLoading = true
      this.showForgotPass = false
      this.$nuxt.$loading.start()

      try {
        await this.$services.auth.createJwt({
          email: this.login.email,
          password: this.login.password
        })

        return this.backToRedirectPath()
      } catch (error) {
        this.errorMessage = error.message
        this.isLoading = false
        this.showForgotPass = true
        this.$nuxt.$loading.finish()
      }
    },

    async onLoginWithFacebook({ response }) {
      this.isLoading = true
      this.errorMessage = ''
      this.showForgotPass = false
      this.$nuxt.$loading.start()

      try {
        await this.$services.auth.createJwtFacebook({
          facebook_id: response.authResponse.userID,
          access_token: response.authResponse.accessToken
        })

        return this.backToRedirectPath()
      } catch (err) {
        this.errorMessage = err.message
      } finally {
        this.isLoading = false
        this.$nuxt.$loading.finish()
      }
    },

    setUserTotalFavorites() {
      this.$store.commit('favorite/UPDATE_TOTAL_FAVORITES', {
        total: this.$auth.user.total_favorites
      })
    }
  }
}
</script>
<style lang="scss">
@import '~/assets/scss/pages/login.scss';
</style>
